<template>
  <div :class="[ $i18n.locale == 'en' ? 'en-css' : '', 'out']" ref="out">
    <div :class="[ $i18n.locale == 'en' ? 'en-css' : '', 'out','empty-out']" v-show="isEmpty">
      <div class="item_nobg" style="
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        box-sizing: border-box;
        background:#fff
      ">
      
      <div class="flex just-sp list_return" >
        <img src="../assets/img/return_index.png" class="return" alt="" @click="goindex" />
        <div class="logo">
          <img src="../assets/img/logo-icon.png" alt="" />
          <div>{{ $t('main.titleCenter') }}</div>
        </div>
        <div class="return"></div>
      </div>
    </div>
        <div class="empty-content">
          <img src="../assets/img/no-data-tishi.png" alt="">
          暂未发布，敬请期待。
        </div>
    </div>
    <div class="item_nobg" style="
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        box-sizing: border-box;
        background:#fff
      ">
      
      <div class="flex just-sp list_return">
        <img src="../assets/img/return_index.png" class="return" alt="" @click="goindex" />
        <div class="logo">
          <img src="../assets/img/logo-icon.png" alt="" />
          <div>{{ $t('main.titleCenter') }}</div>
        </div>
        <div class="return"></div>
      </div>
    </div>

    <van-swipe class="my-swipe l_swiper" @change="swipechange" ref="myswipe" :lazy-render="true">
      <van-swipe-item v-for="(item, index) in pictures" :key="index">
        <img class="swipe_img" v-lazy="item + '?width=750'" @click="changeAct(index)" alt="" srcset="" />
      </van-swipe-item>
      <template #indicator>
        <!-- <div class="custom-indicator">
          {{ current + 1 }}/{{ pictures.length }}
        </div> -->
        <div class="custom_indicator">
          <div class="indicator_flex" v-for="(item, index) in pictures" :key="index">
            <div style="width: 0.0325rem"></div>
            <div :class="['indicator', current == index ? 'indicator_active' : '']" @click="changIndexx(index)"></div>
            <div style="width: 0.0325rem"></div>
          </div>
        </div>
      </template>
    </van-swipe>
    
    <div class="d_enter" v-show="dimensional_href"   @click="go3D">
      <img src="../assets/img/wzw_3D_2.png" alt="">
      <img src="../assets/img/wzw_3D_1.png" alt="">
    </div>
    <!-- <div class="privacy">
      <img :src="dataPic[17]" alt="">
      {{user.name}}专属私人定制
    </div> -->
    <div class="wzw_rz">
        <img src="../assets/img/wzw_rz_success.jpg" alt="">
    </div>
    <div class="item">
      <!-- <div class="flex just-sp item_top">
            <div class="Title">
                <div class="English">BASIC</div>
                <div class="Chinese">基础数据</div>
            </div>
            <div></div>
        </div> -->
      
      <div class="wzw_tr">
        {{name}}
      </div>
      <div class="info_item">
        <div>
          <img :src="dataPic[2]" alt="" />
        </div>
        <div>{{ is_inst == 1 ? $t('main.collinst') : scjFlag ? $t('main.collector') : $t('main.author') }}：</div>
        <div class="user_name" style="font-weight:bold">{{ user.name }}</div>

      </div>
      <div class="info_item" v-show="parseInt(price) > 0">
        <div>
          <img :src="dataPic[3]" alt="" />
        </div>
        <div>{{ $t('main.trvalue') }}</div>
        <img class="question" v-show="help.goods_price" @click="showContent('goods_price')"
          src="../assets/img/wzw_question.png" alt="" srcset="">
        <div class="price">￥{{ format_with_regex(price) }}</div>
        <div class="quxianx" v-if="collector && (collector.status == 3 || collector.status == 1)" @click="goGrap">{{
          $t('main.curve') }}</div>
      </div>
      <div class="info_item" v-show="hasCertificate">
        <div>
          <img style="width:0.13rem" :src="dataPic[4]" alt="" />
        </div>
        <div>{{ $t('main.holdertran') }}：</div>
        <img class="question" v-show="help.holder_certification" @click="showContent('holder_certification')"
          src="../assets/img/wzw_question.png" alt="" srcset="">
        <div class="renzhen noOne" v-if="!collector || (collector.status != 3 && collector.status != 1)">
          {{ $t('main.nyc') }}
        </div>
        <div class="renzhen collector" v-else>
          <img :src="collector.avatar" alt="" />
          <div>{{ collector.name }}</div>
        </div>
        <div style="flex:1"></div>
        <div v-if="collector && (collector.status == 3 || collector.status == 1)" @click="goRecord" class="hisss">
          {{ $t('main.record') }}
          <div class="hisNum">{{ num }}</div>
        </div>
      </div>
      <div class="info_item">
        <div>
          <img :src="dataPic[5]" alt="" />
        </div>
        <div>{{ $t('main.gwtc') }}</div>
        <img class="question" v-show="help.goods_number" @click="showContent('goods_number')"
          src="../assets/img/wzw_question.png" alt="" srcset="">
        <div>{{ serial_number }}</div>
        <div class="copyy" @click="copy_text(serial_number)">
          {{ $t('main.copy') }}
        </div>
      </div>

      
      <div class="info_item" v-show="hasCertificate">
        <div>
          <img :src="dataPic[6]" alt="" />
        </div>
        <div>{{ $t('main.bchash') }}：</div>
        <img class="question" v-show="help.goods_hash" @click="showContent('goods_hash')" src="../assets/img/wzw_question.png"
          alt="" srcset="">
        <div class="overHidd">{{ uuid }}</div>
        <div class="copyy" @click="copy_text(uuid)">
          {{ $t('main.copy') }}
        </div>
      </div>
      <div class="info_item" style="padding-bottom: 0.1rem">
        <div>
          <img :src="dataPic[7]" alt="" />
        </div>
        <div>{{ $t('main.readTimes') }}</div>
        <div class="color17">{{ browse_times }}次</div>
      </div>
      <div class="cw-right">
        <div class="item-logo">
          <div class="icon-logo" @click="changeSharePop">
            <div><img :src="dataPic[8]" alt=""></div>
            <div class="title">{{ $t('main.share') }} ({{ share_num }})</div>
          </div>
        </div>
        <div class="item-logo">
          <div class="icon-logo" v-if="!is_like" @click="like()">
            <div>
              <img :src="dataPic[9]" alt="">
            </div>
            <div class="title">{{ $t('main.liked') }} ({{ like_num }})</div>
          </div>

          <div class="icon-logo" v-if="is_like" @click="like()">
            <div>
              <img :src="dataPic[10]" alt="">
            </div>
            <div class="title title-color">{{ $t('main.liked') }} ({{ like_num }})</div>
          </div>
        </div>
        <div class="item-logo">
          <div class="icon-logo" v-show="save_status == 0" @click="userCollection">
            <div><img :src="dataPic[11]" alt=""></div>
            <div class="title">{{ $t('main.favore') }} ({{ total_save_num }})</div>
          </div>
          <div class="icon-logo" v-show="save_status == 1" @click="userCollection">
            <div><img :src="dataPic[12]" alt=""></div>
            <div class="title title-color">{{ $t('main.favorite') }} ({{ total_save_num }})</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 鉴赏图片已上传 -->
    <div class="imgUpdate" v-if="detail_info && detail_status != 2">
      <img @click="toImgUpdate()" src="../assets/img/style.png" alt="">
    </div>

    <div class="item_tg" style="padding:0.1rem 0" v-if="pt_list.length">
      <div class="pt_text">
        *{{ $t('detail.pro') }}
      </div>
      <div class="pt_logo_list">
        <div :class="['pt_logo', share_act_index == index ? 'act_logo' : '']" v-for="(item, index) in pt_list"
          :key="index" @click="changeIndex(index)">
          <img :src="item.pl_pic.replace(/((^[A-Za-z]{4,5})+\:)/, protocol)" alt="">
          <div class="logo_tip"></div>
        </div>
      </div>
      <div :class="['pt_sm', share_act_index == 0 ? 'pt_sm_0' : '']">
        <div class="pt_sm_text">
          <span>
            <span class="pt_sm_title">{{ pt_list[share_act_index].name }}</span>
            {{ pt_list[share_act_index].info }}
          </span>
        </div>
        <div class="seeMore" @click="change_share_show()">{{ $t('main.check') }}</div>
      </div>

      <div class="pt_text" style="text-align:end;">
        *{{ $t('detail.login') }}
      </div>
    </div>

    <share :list="pt_list" ref="share" :actIndex="share_act_index"></share>
    <div class="item" style="margin-bottom: 0.14rem">
      <div class="flex just-sp item_top">
        <div class="cw_title">{{ $t('main.trdata') }}</div>
      </div>
      <div class="info_item">
        <div>
          <img :src="dataPic[13]" alt="" />
        </div>
        <div>{{ $t('main.trlevel') }}</div>
        <img class="question" v-show="help.goods_levels" @click="showContent('goods_levels')"
          src="../assets/img/wzw_question.png" alt="" srcset="">
        <div :style="level_name == '稀有珍宝' ? 'color:#8574fa' : ''">
          {{ level_name }}
        </div>
      </div>
      <div class="detail_item">
        <div>
          <div>
            <img :src="dataPic[14]" alt="" />
          </div>
          <div>{{ $t('main.trdata') }}：</div>
          <div>
            <div v-for="(item, index) in info" :key="index" class="color17">
              {{ info[index] }}
            </div>
          </div>
        </div>
      </div>
      <div class="info_item">
        <div>
          <img :src="dataPic[15]" alt="" />
        </div>
        <div>{{ scjFlag ? $t('main.colltime') : this.$t('main.timeofcrea') }}：</div>
        <img class="question" v-show="help.goods_create_time" @click="showContent('goods_create_time')"
          src="../assets/img/wzw_question.png" alt="" srcset="">
        <div>{{ manufacturing_date }}</div>
      </div>
     
      <div class="detail_item moreText" style="padding-bottom: 0.1rem">
        <div>
          <div>
            <img :src="dataPic[18]" alt="" />
          </div>

          <div>{{ $t('main.trdes') }}：</div>
          <img class="question" v-show="help.edit_goods_describe" @click="showContent('edit_goods_describe')"
            src="../assets/img/wzw_question.png" alt="" srcset="">
        </div>
        <div>
          <div :class="[zb_JSmore ? '' : 'noMore']" v-html="description"></div>
        </div>
        <div class="showMore" v-if="is_description" @click="zb_JSmore = !zb_JSmore">
          {{ zb_JSmore ? $t('main.putaway') : $t('main.seemore') }}
        </div>
      </div>
    </div>


   
    <div class="item" style="padding:0 0.15rem">
        <div class="pr_top">名家简介</div>
        <div class="pr_master">
            <div class="pr_master_avatar">
              <img :src="user.pic" alt="">
            </div>
            <div class="pr_tt_info">
                <div>{{user.name}}</div>
                <div>{{ user.title }}</div>
            </div>
            <div class="pr_tt_follow" @click="concern" :style="follow_status == 0 ? 'color:#000;':'color:#929292;'">{{follow_status == 0 ? '+ 关注' : $t('main.followed')}}</div>
        </div>
        <div class="pr_t_detail">
            <div style="font-size:0.14rem" :class="[JSmore ? '' : 'noMore2']" v-html="user.content"></div>
            <div class="pr_show_more" v-if="is_user_content" @click="JSmore = !JSmore">
                {{ JSmore ? $t('main.putaway') : $t('main.seemore') }}
            </div>
        </div>
        <div class="pr_t_honor">
            <div class="pr_t_h_top">
                    <img src="../assets/img/wzw_honor.png" alt />
                    作者荣誉
            </div>
            <div class="pr_t_h_h" >
              <div style="font-size:0.14rem" v-html="user.honor" :class="[HYmore ? '' : 'noMore2']"></div>
            </div>
            <div class="showMore" v-if="is_user_honor" @click="HYmore = !HYmore">
                    {{ HYmore ? $t('main.putaway') : $t('main.seemore') }}
            </div>
        </div>
    </div>
    

    
    <div class="item" v-show="newList.length">

      <div class="flex  item_top">
        <div class="cw_title-wrap">
          <div class="cw_title ">{{ is_inst == 1 ? $t('main.newToCoInst') : $t('main.celebrityNew') }}</div>
          <div class="question-left">
            <img class="question " v-show="help.author_news" @click="showContent('author_news')"
              src="../assets/img/wzw_question.png" alt="" srcset="">
          </div>
        </div>
      </div>
      <div class="new_item" v-for="(item, index) in newList" :key="index">
        <div @click="gourl(item.url)">{{ item.title }}</div>
        <div></div>
      </div>
    </div>
    <div class="item last-item" v-show="tuijian_show">
      <div class="flex just-sp item_top">
        <div class="cw_title">其他作品</div>
      </div>

      <div class="flex good_list">
        <div class="good" v-for="(item, index) in tuijian" :key="index" @click="goDetail(item.id)">
          <!-- <img v-lazy="(item.pictures[0] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/,protocol)" alt="" />
          <div class="good_text">{{ item.name }}</div> -->
          <div class="good-image-left">
            <img class="good-image" v-lazy="(item.pictures[0] + '?width=216')" alt="" />
            <div class="icon-min" v-show="item.level.name === '稀有珍宝'">
              <img src="../assets/img/logo-icon-right.png" alt="" />
            </div>
          </div>
          <div class="good-title">
            <div class="good_name">{{ item.name }}</div>
            <div class="good_price" :style="parseInt(item.price) == 0 ?'opacity:0':'' ">估值：￥<span style="font-size:0.15rem;font-weight:bold">{{ thousands(item.price) }}</span></div>
            <div class="good_description">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="scroll-loding" v-show="showLoading"><van-loading size="24" color="#AD5551" /></div> -->


    <div class="text_bottom">
      <div class="report" >
        <div style="display:flex;align-items:center" @click="clickReport">
          <img :src="dataPic[0]" alt=""> {{ $t('main.report') }}
        </div>
        
      </div>
      <span style="color: #b6b6b6">{{ $t('main.copyacon') }}<br /></span>
      {{ $t('main.tppswsc') }}<br />
      {{ $t('main.platTips') }}<br />
      <span style="color: #b6b6b6">{{ $t('main.noaldar') }}</span>
    </div>



    <div class="password-box pop" v-show="to_pop" @click="to_pop = !to_pop">
      <div class="pop-in1" @click.stop>
        <div class="title">
          <img src="../assets/img/title-left-icon.png" alt="" />
          <span>{{ $t('main.purchApply') }}</span>
          <img src="../assets/img/title-right-icon.png" alt="" />
        </div>
        <div class="con">
          <div class="con-tips">
            {{ $t('main.infoM') }}</br>
            {{ $t('main.clickScan') }} <span @click="show_qrcode = !show_qrcode"
              style="text-decoration:underline;color:#9a2a25;cursor: pointer;">{{ $t('main.gwcoa') }}</span>
            ，{{ $t('main.glmn') }}
          </div>
          <div class="pass-input">
            <div class="">
              <label for="">{{ $t('main.formofadd') }}</label><input v-model="to_name" maxlength="4"
                :placeholder="$t('main.pfiyname')" />
            </div>
            <div class="">
              <label for="">{{ $t('main.phoneNum') }}</label><input v-model="to_phone" maxlength="11" type="tel"
                :placeholder="$t('main.piyphone')" />
            </div>
            <div class="">
              <label for="">{{ $t('main.estbid') }}</label><input v-model="to_price"
                oninput="if(value.length>9)value=value.slice(0,9)" :placeholder="$t('main.peestbid')" type="number" />
            </div>
            <div class="" style="align-items:flex-start">
              <label style="margin-top:0.08rem" for="">{{ $t('main.purchMess') }}</label>
              <textarea v-model="to_content" maxlength="150" :placeholder="$t('main.piypmess')"></textarea>
            </div>
          </div>
          <div class="err-tips" v-show="to_err">
            <img src="../assets/img/err-tips-icon.png" alt="" />
            {{ msg }}
          </div>
        </div>
        <div class="confirm-btn" @click="post_price">{{ $t('main.submit') }}</div>
        <div class="con-title">*{{ $t('main.epcosr') }}*</div>
      </div>
    </div>

    <div class="pop" v-show="show_qrcode" @click.stop="show_qrcode = !show_qrcode">
      <div class="pop-qrcode">
        <img src="../assets/img/gzhcode.png" alt="">
      </div>
    </div>

    <div class="share_pop pop" v-show="share_pop">
      <div class="share_top">
        <img src="../assets/img/share_top.png" alt="">
      </div>
      <div class="share_bottom" @click="changeSharePop">
        <img src="../assets/img/share_close.png" alt="">
      </div>
    </div>
    <div class="AR-Read-top">
      <div class="AR-Read" v-if="audioStatus == 2 || no_open_audio">
        <img class="zanting" :style="no_open_audio ? 'filter: grayscale(1);' : ''" v-show="!isShowARbox" @click="bofangAR"
          src="../assets/img/aiyy-zanting.png" alt="">
        <div class="AR-bofang" v-show="isShowARbox">
          <div class="AR-box">
            <img src="../assets/img/aiyy-zanting.png" v-show="speakShowRead == 2" @click="speakRead" alt="">
            <img src="../assets/img/aiyy-bofang.png" v-show="speakShowRead == 1" @click="speakRead" alt="">
            <div class="tiao-wrap"><van-slider @change="changeEnd" v-model="valueLeangth" button-size="12px"
                bar-height="2px" active-color="#210FA6" inactive-color="#B6AEDC" /></div>
            <div class="time">{{ durationTime }}</div>
            <div class="guanbi" @click="guanbi"><img src="../assets/img/guanbi-aryy-ccc.png" alt=""></div>
          </div>
        </div>
      </div>
      <audio controls ref="audio" class="aud" :src="audioSrc" @ended="resetSpeak"></audio>
    </div>
    <div class="fixed_buttom">
      <div class="speak_fixed">
        <div class="repair" v-if="scjFlag">
          <div>
            <img class="guanbi" src="../assets/img/guanbi.png" alt="" @click="guanbiBtn" v-if="!showBtn">
          </div>
          <img class="repair-rukou" src="../assets/img/repair-rukou-logo.png" @click="repairBtn" alt=""
            :class="showBtn ? 'active' : ''">
        </div>

      </div>
      <!-- <div class="wtb_fixed" v-show="is_to_buy">
        <img class="question1" v-show="help.want_buy" @click="showContent('want_buy')" src="../assets/img/wzw_question.png"
          alt="" srcset="">
        <div class="wtb" @click="wtb" :style="!is_invent ? 'filter:grayscale(100%);' : ''">
          {{ !is_invent ? $t('main.nfpst') : $t('main.wanttobuy') }}</div>
      </div> -->
    </div>
    <!-- <div class="detail_right" :style="is_to_buy ? '' : 'transform: rotateY(180deg);'" @click="is_to_buy = !is_to_buy">
    </div> -->
    <!--  -->
    <div class="pop-pus" v-show="showPrpPus">
      <div class="bg-img">
        <div class="title-wrap">
          <div class="title-prompt">{{ $t('main.yhsapr') }} {{ $t('main.cotvtprm') }}</div>
        </div>
        <div class="sure-logo">
          <div class="button" @click="concatShow(2)">{{ $t('main.cancel') }}</div>
          <div class="button" @click="concatShow(1)">{{ $t('main.confIrm') }}</div>
          <!-- <img class="sure-img" src="../assets/img/zm-cancel-logo.png"   alt=""> -->
          <!-- <img class="sure-img" src="../assets/img/no-data-sure.png"   alt="">  -->
        </div>
      </div>

    </div>
    <changelang></changelang>
    <privacy ref="privacy" :more="$route.query.id_code?`&id_code=${$route.query.id_code}`:''"></privacy>
  </div>
</template>

<script>
import share from "../components/share.vue"
import changelang from "../components/changelangtype.vue"
import { ImagePreview, Dialog, Toast, Slider } from "vant";
import re from"../assets/img/report-icon.png"
import mc from"../assets/img/mc.png"
import zz from"../assets/img/wzw_zz.png"
import jz from"../assets/img/wzw_gz.png"
import rz from"../assets/img/wzw_rz.png"
import bm from"../assets/img/wzw_bm.png"
import qk from"../assets/img/wzw_hash.png"
import yd from"../assets/img/wzw_yd.png"
import share_icon from"../assets/img/wzw_share.png"
import uul from"../assets/img/wzw_unlike.png"
import ulike from"../assets/img/wzw_like.png"
import uuc from"../assets/img/wzw_sc.png"
import ucoll from"../assets/img/wzw_sc_r.png"
import dj from"../assets/img/wzw_dj.png"
import sj from"../assets/img/wzw_sj.png"
import ti from"../assets/img/wzw_ti.png"
import hy from"../assets/img/hy.png"
import ms from "../assets/img/wzw_ms.png"
import ds from "../assets/img/ds.png"
import jj from "../assets/img/jj.png"
import privacy from "../components/privacy.vue"


export default {
  components: {
    share,
    changelang,
    privacy
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      if (newVal == oldVal) {
        return
      }
      if (this.$route.query.locale) {

        if (!this.isloading) {
          this.$router.go(0)
        }
        this.isloading = false
      } else {

        this.$router.go(0)
      }


    }
  },
  data() {
    return {
      name: "",
      pictures: [],
      info: [],
      manufacturing_date: "",
      description: "",
      honor: [],
      price: "",
      detail_info: null,
      detail_status: 0,
      is_rare: "",
      serial_number: "",
      uuid: "",
      is_recommend: "",
      collector: "",
      artist_id: "",
      level_name: "",
      browse_times: 0,
      share_num: 0,
      like_num: 0,
      newList: [],
      tuijian_show: true,
      user: {
        name: "",
        title: "",
        pic: "",
        content: "",
        honor: "",
        birthday: '',
        gender: '',
      },
      hasCertificate: false,
      is_like: false,
      tuijian: [{
        description: "",
        id: "",
        level: {
          name: ""
        },
        level_id: "",
        name: "",
        pictures: [],
        price: "",
      }],
      ifName: "",
      activeIndex: 0,
      JSmore: false,
      HYmore: false,
      isStart: false,
      zb_JSmore: false,
      zb_HYmore: false,
      showBigImg: false,
      is_description: false,
      is_user_content: false,
      is_user_honor: false,
      current: 0,
      current1: 0,
      current2: 0,
      is_scan: false,
      scjFlag: false,
      num: 0,
      to_name: '',
      to_phone: '',
      to_price: '',
      to_pop: false,
      to_content: '',
      msg: '',
      to_err: false,
      shuru: false,
      is_to_buy: true,
      pt_list: [],
      pt_more: false,
      share_show: false,
      share_act_index: 0,
      pic1: '',
      pic2: '',
      share_pop: false,
      protocol: 'http',
      show_qrcode: false,
      help: {
        goods_price: null,
        holder_certification: null,
        goods_number: null,
        goods_hash: null,
        goods_levels: null,
        goods_create_time: null,
        personal_profile: null,
        author_honor: null,
        author_news: null,
        want_buy: null,
        edit_goods_honor: null,
        edit_goods_describe: null,
        goods_cert: null,
        goods_artist_cert: null,
        total_save_num: 0
      },
      is_load_img: [
        true,
        false,
        false,
        false,
        false,
        false
      ],
      is_invent: false,
      showLoading: false,
      page: 1,
      loading1: false,
      hasMorePages: true,
      certificate_info: [],
      mingjia_certificate_info: [],
      arrImage: [],
      arrImage1: [],
      speakShowRead: 0,
      audioSrc: "",
      audioStatus: "",
      pay_status: false,
      user_id: "",
      showPrpPus: false,
      save_status: 0,
      total_save_num: 0,
      follow_status: 0,
      collector_id: 0,
      shouchang: 0,
      goodsId: 0,
      durationTime: '00:00',
      currentLeanth: 0,
      durationLeanth: 0,
      valueLeangth: 0,
      isShowARbox: false,
      showBtn: false,
      birthdayLeangth: 0,
      audioType: 0,
      no_open_audio: false,
      isloading: true,
      dimensional_href: "",
      is_inst: 0,
      dataPic:[
        re,
        mc,
        zz,
        jz,
        rz,
        bm,
        qk,
        yd,
        share_icon,
        uul,
        ulike,
        uuc,
        ucoll,
        dj,
        sj,
        ti,
        hy,
        '',
        ms,
        ds,
        jj
      ],
      isEmpty:false
    };
  },
  destroyed(){
    if(this.hasHead){
       
    let callStyle = document.querySelector("style[data-callType='callStyle']")
    document.head.removeChild(callStyle)
    }
  },
  created(){
    if(this.$route.query.__params){
     let json = JSON.parse(this.$base64.decode(this.$route.query.__params))
     
     let query = json
     this.$route.params.id = query.id
    }
    if(this.$route.params.id == 785){
       let status = ''
        if(this.$route.query.status){
            status='?status=1'
        }
      this.$router.replace('/detail_unit/'+this.$route.params.id+status)
    }
  },
  async mounted() {
    this.$refs.audio.oncanplay = () => {

      var time = this.$refs.audio.duration;
      //分钟
      if (!time) {
        return
      }
      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds
      this.durationTime = minutes + " : " + seconds
      this.currentLeanth = this.$refs.audio.currentTime
      this.durationLeanth = this.$refs.audio.duration
    }
    this.$refs.audio.ontimeupdate = () => {
      if (!this.$refs.audio.duration) {
        return
      }
      let length = this.$refs.audio.currentTime / this.durationLeanth * 100;
      this.valueLeangth = length

      var time = this.$refs.audio.duration - this.$refs.audio.currentTime;

      var minute = time / 60;
      var minutes = parseInt(minute);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      //秒
      var second = time % 60;
      var seconds = Math.round(second);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.durationTime = minutes + " : " + seconds
    }
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale
      window.localStorage.setItem('lang', this.$route.query.locale)
      let nowUrl = this.$route.fullPath.split('?')[0]
      if(this.$route.query.__params){
        nowUrl = nowUrl + '?__params='+this.$route.query.__params
      }
      window.location.replace(nowUrl)
    }

    // this.$refs.out.addEventListener('scroll',this.throttle(this.scrollFn,500))

    if (!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}') {
      this.help = {
        goods_price: null,
        holder_certification: null,
        goods_number: null,
        goods_hash: null,
        goods_levels: null,
        goods_create_time: null,
        personal_profile: null,
        author_honor: null,
        author_news: null,
        edit_goods_honor: null,
        edit_goods_describe: null,
        goods_cert: null,
        goods_artist_cert: null
      }
    } else {

      if (this.$i18n.locale == 'en') {
        let data = JSON.parse(localStorage.getItem('helpinfo'))
        Object.keys(data).forEach((item) => {
          if (item.indexOf('_intl') != -1) {
            let label = item.split('_intl')[0]
            if (data[item]) {
              data[label] = data[item]
            }
          }
        })
        this.help = data
      } else {
        this.help = JSON.parse(localStorage.getItem('helpinfo'))
      }
    }
    // console.log(this.help)
    this.protocol = location.protocol
    this.pic1 = require("@/assets/img/like_icon.png");
    this.pic2 = require("@/assets/img/like_icon_gray.png");
    let token = localStorage.getItem('user_token')
    if (this.$route.query.code && !token) {
      let querys= ''
      if(this.$route.query.phone){
        let phone =this.getQueryString('phone')
        let nickName = this.getQueryString('phone_code')
        querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
      }
      await this.axios.get('/auth/nt?code=' + this.$route.query.code + querys).then((res) => {
        if(res.data.code == -1 && res.data.msg == '请填写手机号'){
            
            this.$refs.privacy.openPopup()
            return
          }
        window.localStorage.setItem('user_token', res.data.data.token)
      })
    }
    if (this.$route.query.status) {
    } else {
      this.is_scan = true;
    }


    let url = location.protocol + '//' + location.host
    this.axios.post('/auth/wechatConfig', {
      url: url + this.$store.state.jssdkUrl,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline']
    }).then((res) => {

      let json = JSON.parse(res.data.data.data)

      wx.config({
        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
        appId: json.appId, // 必填，公众号的唯一标识
        timestamp: json.timestamp, // 必填，生成签名的时间戳
        nonceStr: json.nonceStr, // 必填，生成签名的随机串
        signature: json.signature, // 必填，签名
        jsApiList: json.jsApiList // 必填，需要使用的 JS 接口列表
      })

    })
    let locale = this.$route.fullPath.indexOf('?') == -1 ? ('?locale=' + this.$i18n.locale) : ('&locale=' + this.$i18n.locale)
    wx.updateAppMessageShareData({
      title: this.name, // 分享标题
      desc: '—— ' + this.user.name + '\n长城文创珍宝中心', // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pictures[1] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    })
    wx.updateTimelineShareData({
      title: this.user.name + '--' + this.name, // 分享标题
      desc: '—— ' + this.user.name + '\n长城文创珍宝中心', // 分享描述
      link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      imgUrl: (this.pictures[1] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol), // 分享图标
      success: () => {
        // this.axios.get(`goods/share/${this.$route.params.id}`).then((res)=>{
        //   this.share_num++
        // })
      }
    })


    // this.pictures = ['https://i.postimg.cc/kXwrzRHm/m1.jpg','https://i.postimg.cc/kXwrzRHm/m1.jpg']
    // this.is_rare = true
    let newdata =''
    await this.axios.post('common/encrypt',{goods_id:this.$route.params.id,id_code:this.$route.query.id_code}).then((res)=>{
      newdata = res.data.data.data_encrypted
    })
    await this.axios
      .get("/goods/info" + "?data=" + newdata)
      .then((res) => {
        if(res.data.code != 0){
          this.isEmpty = true
          return
        }
        if (this.$i18n.locale == 'en') {
          let intl_artist = res.data.data.artist_edit
            ? res.data.data.artist_edit
            : res.data.data.artist;
          Object.keys(intl_artist).forEach((item) => {
            if (item.indexOf('_intl') != -1) {

              let label = item.split('_intl')[0]
              if (res.data.data.artist_edit) {

                if (res.data.data.artist_edit[item]) {
                  res.data.data.artist_edit[label] = res.data.data.artist_edit[item]
                }

              } else {
                if (res.data.data.artist[item]) {
                  res.data.data.artist[label] = res.data.data.artist[item]
                }
              }
            }
          })
          let data = res.data.data
          Object.keys(data).forEach((item) => {
            if (item.indexOf('_intl') != -1) {
              let label = item.split('_intl')[0]
              if (res.data.data[item]) {
                res.data.data[label] = res.data.data[item]
              }
            }
          })
          if (res.data.data.level.name_intl) {
            res.data.data.level.name = res.data.data.level.name_intl
          }
        }

        this.audioType = res.data.data.audio_type
        this.goodsId = res.data.data.id
        this.$route.params.id = res.data.data.id
        this.name = res.data.data.name;
        this.dimensional_href = res.data.data.dimensional_href
        this.pictures = res.data.data.pictures;
        this.detail_info = res.data.data.detail_info;
        this.detail_status = res.data.data.detail_status
        this.info = res.data.data.info;
        this.manufacturing_date = res.data.data.manufacturing_date;
        this.certificate_info = res.data.data.certificate_info ? res.data.data.certificate_info : [];
        // this.mingjia_certificate_info = res.data.data.artist_edit ? JSON.parse(res.data.data.artist_edit.certificate_info) : [];
        this.is_like = res.data.data.is_like
        this.total_save_num = res.data.data.view.total_save_num ? res.data.data.view.total_save_num : 0
        this.save_status = res.data.data.is_save ? res.data.data.is_save : 0
        this.level_name = res.data.data.level ? res.data.data.level.name : "";
        this.num = res.data.data.collect_num ? res.data.data.collect_num : 0;
        if (this.$i18n.locale == 'en' && res.data.data.spread_info) {
          Object.keys(res.data.data.spread_info).forEach((item) => {
            res.data.data.spread_info[item].name = res.data.data.spread_info[item].key
            if (res.data.data.spread_info[item].info_intl) {
              res.data.data.spread_info[item].info = res.data.data.spread_info[item].info_intl
            }
          })
        }
        
        let pt_json = res.data.data.spread_info ? res.data.data.spread_info : {}
        let pt_list = []
        Object.keys(pt_json).forEach((key) => {
          pt_list.push(pt_json[key])
        })
        this.pt_list = pt_list
        let test = document.createElement("div");
        test.style.width = "3.135rem";
        test.style.fontSize = "0.14rem";
        test.innerText = "测试";
        document.body.appendChild(test);
        let test_height = test.clientHeight;

        document.body.removeChild(test);
        this.honor = res.data.data.honor ? res.data.data.honor.split("\n") : [];
        let desList = res.data.data.description ? res.data.data.description.split("\n") : [];
        for (let i = 0; i < desList.length; i++) {
          if (i == 0) {
            this.description = desList[i];
            continue;
          }
          this.description = this.description + "<br>" + desList[i];
        }

        let el = document.createElement("div");
        el.style.width = "3.135rem";
        el.style.fontSize = "0.14rem";
        el.innerHTML = this.description;
        document.body.appendChild(el);
        if (el.clientHeight / test_height > 5) {
          this.is_description = true;
        }
        document.body.removeChild(el);
        this.price = res.data.data.collector
          ? res.data.data.collector.price
          : res.data.data.price;
        this.is_rare = res.data.data.is_rare;
        this.serial_number = res.data.data.serial_number;
        this.uuid = res.data.data.uuid;
        this.browse_times = res.data.data.view.total_browse_num;
        this.share_num = res.data.data.view.total_share_num
        this.like_num = res.data.data.view.total_like_num
        this.collector = res.data.data.collector;
        this.is_recommend = res.data.data.is_recommend;
        this.user_id = res.data.data.apply_buy_id;


        let now_artist = res.data.data.artist_edit
          ? res.data.data.artist_edit
          : res.data.data.artist;
        this.mingjia_certificate_info = now_artist.certificate_info ? now_artist.certificate_info : [];
        this.is_inst = now_artist.is_collector_inst
        this.is_invent = !!(res.data.data.is_can_buy == 1)
        this.artist_id = res.data.data.artist_id;
        if (res.data.data.certificate) {
          this.pictures.unshift(res.data.data.certificate);
          this.hasCertificate = true
        }
        this.user.name = now_artist.name;
        this.user.birthday = now_artist.birthday ? now_artist.birthday.split(" ")[0] : '';
        if (now_artist.gender != 0) {
          this.user.gender = now_artist.gender == 1 ? this.$t('main.male') : this.$t('main.female');
        }
        this.birthdayLeangth = now_artist.age
        this.user.title = now_artist.title;
        this.user.pic = now_artist.pic;

        let conList = now_artist.content ? now_artist.content.split("\n") : [];
        for (let i = 0; i < conList.length; i++) {
          if (i == 0) {
            this.user.content = conList[i];
            continue;
          }
          this.user.content = this.user.content + "<br>" + conList[i];
        }
        let el1 = document.createElement("div");
        el1.style.width = "3.46rem";
        el1.style.fontSize = "0.15rem";
        el1.innerHTML = this.user.content;
        document.body.appendChild(el1);
        if (el1.clientHeight / test_height > 5) {
          this.is_user_content = true;
        }

        document.body.removeChild(el1);
        // this.user.honor =  now_artist.honor.split('\n')
        let userhonList = now_artist.honor ? now_artist.honor.split("\n") : [];
        for (let i = 0; i < userhonList.length; i++) {
          if (i == 0) {
            this.user.honor = userhonList[i];
            continue;
          }
          this.user.honor = this.user.honor + "<br>" + userhonList[i];
        }

        let el2 = document.createElement("div");
        el2.style.width = "3.46rem";
        el2.style.fontSize = "0.15rem";
        el2.innerHTML = this.user.honor;
        document.body.appendChild(el2);
        if (el2.clientHeight / test_height > 5) {
          this.is_user_honor = true;
        }

        document.body.removeChild(el2);

        if (now_artist.type == 2) {
          this.scjFlag = true;
        }
      }).catch(err=>{
         this.isEmpty = true
      });
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title: this.name, // 分享标题
        desc: '—— ' + this.user.name + '\n长城文创珍宝中心', // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pictures[0] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol), // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        success: () => {

          this.axios.get(`goods/share/${this.$route.params.id}`).then((res) => {
            this.share_num++
          })
        }
      });
      wx.onMenuShareTimeline({
        title: this.user.name + '--' + this.name, // 分享标题
        desc: '—— ' + this.user.name + '\n长城文创珍宝中心', // 分享描述
        link: url + this.$route.fullPath + locale, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: (this.pictures[0] + '?width=200').replace(/((^[A-Za-z]{4,5})+\:)/, location.protocol), // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        success: () => {

          this.axios.get(`goods/share/${this.$route.params.id}`).then((res) => {
            this.share_num++
          })
        }
      })
    })

    this.axios.get("/news/list/" + this.artist_id).then((res) => {
      if (res.data.data.list) {
        if (this.$i18n.locale == 'en') {
          for (let i = 0; i < res.data.data.list.length; i++) {
            if (res.data.data.list[i].title_intl) {
              res.data.data.list[i].title = res.data.data.list[i].title_intl
            }
          }
        }
        this.newList = res.data.data.list;
      } else {
        this.newList = [];
      }
    });
    let artist_id = ''
    await this.axios.post('common/encrypt',{artist_id:this.artist_id,more:0,count:4,page:1}).then((res)=>{
      artist_id = res.data.data.data_encrypted
    })
    this.axios
      .get("/goods/recommend?data=" + artist_id)
      .then((res) => {
        this.hasMorePages = res.data.data.hasMorePages
        if (res.data.data.items && this.$i18n.locale == 'en') {
          for (let i = 0; i < res.data.data.items.length; i++) {
            res.data.data.items[i].name = res.data.data.items[i].name_intl ? res.data.data.items[i].name_intl : res.data.data.items[i].name
            res.data.data.items[i].description = res.data.data.items[i].description_intl ? res.data.data.items[i].description_intl : res.data.data.items[i].description
          }
        }
        this.tuijian = res.data.data.items || [];
        // if (this.tuijian.length == 1) {
        //   if (this.tuijian[0].id == this.$route.params.id)
        //     this.tuijian_show = false;
        // }
        if (this.tuijian.length < 1) {
          this.tuijian_show = false;
        }
      });
    let getAudioStatus = ''
      await this.axios.post('common/encrypt',{goods_id: this.goodsId}).then((res)=>{
        getAudioStatus = res.data.data.data_encrypted
      })
    this.axios.post("/goods/getAudioStatus", { data: getAudioStatus })
      .then(((res) => {

        this.audioSrc = this.$i18n.locale == 'en' ? res.data.data.audio_intl : res.data.data.audio
        this.audioStatus = this.$i18n.locale == 'en' ? res.data.data.audio_status_intl : res.data.data.audio_status
        // this.$nextTick(()=>{
        //     console.log(this.$refs.audio.duration)
        // })
      }))
    // this.axios.post('/artist/detail',{id:this.artist_id}).then((res)=>{




    // })
    token = localStorage.getItem('user_token')
    if (token) {
      this.axios.post("/artist/getFollowStatus", { artist_id: this.artist_id }).then((res) => {
        this.follow_status = res.data.data.follow_status ? res.data.data.follow_status : 0
      })
      this.axios.get('/auth/me').then((res) => {
        this.collector_id = res.data.data.user.artist_id
        this.shouchang = res.data.data.user.collector_id
        if ((this.collector_id == this.artist_id || this.shouchang == this.artist_id) && this.audioType == 0) {
          this.no_open_audio = true
        }
      })
    }

  },
  methods: {
     changeSharePop(){
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.share_pop = !this.share_pop
    },
    getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
    addshuiyin(index) {
      if (this.is_load_img[index]) {
        return
      }
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let img = document.getElementById('load' + index)
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.font = '30px PingFang';
      ctx.globalAlpha = 0.5
      ctx.beginPath();
      let shuiyin = document.getElementById('shuiyin')
      ctx.drawImage(shuiyin, (canvas.width - (shuiyin.width) / 3), 0, (shuiyin.width) / 3, (shuiyin.height) / 3);
      ctx.globalAlpha = 1;
      this.is_load_img[index] = true
      this.pictures[index] = canvas.toDataURL("image/png")
    },
    changeEnd(value) {
      this.$refs.audio.currentTime = value * this.durationLeanth / 100

      if (this.$refs.audio.paused) {
        this.$refs.audio.play()
        this.speakShowRead = 1
      }
    },
    bofangAR() {
      if (this.no_open_audio) {
        this.axios.post('/audio/getAudioByGoods', { goods_id: this.$route.params.id, artist_type: this.scjFlag ? 2 : 1 }).then((res) => {
          if (res.data.data.audio_count != 0) {
            weui.confirm(this.$t('main.cthnaw', { N: res.data.data.audio_count }), {
              title: this.$t('main.actaivoice'),
              buttons: [
                { label: this.$t('main.cancel'), type: 'primary', onClick: () => { } },
                {
                  label: this.$t('main.dredge'), type: 'primary', className: 'warn_dialog', onClick: () => {
                    this.axios.post('/audio/payGoodsAudioByCount', { goods_id: this.$route.params.id, artist_type: this.scjFlag ? 2 : 1 }).then((res) => {
                      if (res.data.code == 0) {
                        setTimeout(() => {
                          this.no_open_audio = false
                          weui.alert(this.$t('main.saagip'), { buttons: [{ label: this.$t('main.confIrm') }] })
                        }, 500)
                      } else {
                        setTimeout(() => {
                          weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
                        }, 500)
                      }
                    })
                  }
                },
              ]
            })
          } else {
            weui.confirm(this.$t('main.cthnpta', { N: res.data.data.rule_money }), {
              title: this.$t('main.actaivoice'),
              buttons: [
                { label: this.$t('main.cancel'), type: 'primary', onClick: () => { } },
                {
                  label: this.$t('main.dredge'), type: 'primary', className: 'warn_dialog', onClick: () => {
                    this.axios.post('/order/payAudioLogs', { goods_id: this.$route.params.id, type_id: res.data.data.rule_id }).then((res) => {
                      if (res.data.code == 0) {
                        let wxpay = res.data.data
                        WeixinJSBridge.invoke(
                          'getBrandWCPayRequest', {
                          appId: wxpay.appId,
                          timeStamp: wxpay.timestamp,
                          nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
                          package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                          signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                          paySign: wxpay.paySign,

                        }, (res) => {
                          if (res.err_msg == "get_brand_wcpay_request:ok") {
                            setTimeout(() => {
                              this.no_open_audio = false
                              weui.alert(this.$t('main.saagip'), { buttons: [{ label: this.$t('main.confIrm') }] })
                            }, 500)
                          } else {
                            setTimeout(() => {
                              weui.alert(this.$t('main.payfail'), { buttons: [{ label: this.$t('main.confIrm') }] })
                            }, 500)
                          }
                        }
                        )
                      }
                    })
                  }
                },
              ]
            })
          }
        })
        return
      }
      this.$refs.audio.play()
      this.speakShowRead = 1
      this.isShowARbox = !this.isShowARbox

    },
    guanbi() {
      this.$refs.audio.pause()
      this.isShowARbox = !this.isShowARbox
    },
    speakRead() {
      if (this.$refs.audio.paused) {
        this.$refs.audio.play()
        this.speakShowRead = 1
      } else {
        this.$refs.audio.pause()
        this.speakShowRead = 2
      }

    },

    resetSpeak() {
      this.isShowARbox = false
    },
    clickReport() {
      if(!this.$isWeiXin()){
				weui.alert('请在微信浏览器打开')
				return
			}
      this.$router.push({
        path: "/report/" + this.goodsId,
        query: {
          accuse_pattern: 0
        }
      })
    },
    copy_text(text) {
      let u = navigator.userAgent, app = navigator.appVersion;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {
        let save = (e) => {
          e.clipboardData.setData("text/plain", text);
          e.preventDefault(); //阻止默认行为
        };
        document.addEventListener("copy", save);
        document.execCommand("copy");
        Toast.success(this.$t('main.copySucc'));
      } else {
        var input = document.createElement("input");
        //只需要改变取值方式即可
        input.value = text
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
        document.body.removeChild(input);
        Toast.success(this.$t('main.copySucc'));
      }

    },
    changeAct(index) {
      ImagePreview({
        images: this.pictures,
        startPosition: this.current,
        closeable: true,
      });
    },
    changeAct1() {
      this.arrImage = []
      this.certificate_info.forEach(item => {
        this.arrImage.push(item.pic)
      });
      ImagePreview({
        images: this.arrImage,
        startPosition: this.current1,
        closeable: true,
      });
    },
    changeAct2() {
      this.arrImage1 = []
      this.mingjia_certificate_info.forEach(item => {
        this.arrImage1.push(item.pic)
      });
      ImagePreview({
        images: this.arrImage1,
        startPosition: this.current2,
        closeable: true,
      });
    },
    gourl(url) {
      window.location.href = url;
    },
    goDetail(id) {
      if (id == this.$route.params.id) {
        return;
      }
      this.$router.push(`/detail/${id}?status=1`);
    },
    prePic() {
      // wx.previewImage({
      //     current: url, // 当前显示图片的 http 链接
      //     urls: this.pictures // 需要预览的图片 http 链接列表
      // })
      this.showBigImg = true;
    },
    picStart(e) {
      this.isStart = true;
      this.startX = e.changedTouches[0].pageX;

      this.diff = 0;
    },
    picMove(e) {
      if (this.isStart) {
        this.diff = e.changedTouches[0].pageX - this.startX;

        if (this.diff) {
          this.$refs.pic.style.transition = null;
          this.$refs.pic.style.transform = `translateX(${this.diff}px)`;
        }
      }
    },
    picEnd(e) {
      this.isStart = false;

      if (this.diff) {
        this.$refs.pic.style.transition = "0.5s";
        this.$refs.pic.style.transform = null;
        if (this.diff < 0 && this.diff < -100) {
          if (this.activeIndex < this.pictures.length - 1) {
            this.activeIndex++;
          }
        } else if (this.diff > 0 && this.diff > 100) {
          if (this.activeIndex > 0) {
            this.activeIndex--;
          }
        }
      }
    },
    swipechange(e) {
      this.current = e;
    },
    changIndexx(index) {
      this.$refs.myswipe.swipeTo(index);
    },
    changIndexx1(index) {
      this.$refs.myswipe1.swipeTo(index);
    },
    changIndexx2(index) {
      this.$refs.myswipe2.swipeTo(index);
    },
    format_with_regex(number) {
      return !(number + "").includes(".")
        ? // 就是说1-3位后面一定要匹配3位
        (number + "").replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
          return match + ",";
        })
        : (number + "").replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
          return match + ",";
        });
    },
    goindex() {
      if(window.history.length == 1){
        this.axios.post('auth/getUrlScheme').then((res)=>{
          let url = res.data.data.data.openlink
        
					window.location.href = url
        })
      }else{
        this.$router.back()
      }
    },
    goGrap() {
      this.$router.push(`/grap/${this.$route.params.id}`);
    },
    goRecord() {
      this.$router.push(`/record/${this.$route.params.id}`);
    },
    async wtb() {
      let token = localStorage.getItem('user_token')
      if (!this.is_invent) {
        Toast(this.$t('main.nfpst'))
        return
      }

      if (token) {
        await this.axios.post("/collection/getApplyBuyStatus", { goods_id: this.$route.params.id }).then((res) => {
          this.pay_status = res.data.data.pay_status
        })
        if (this.pay_status) {
          this.showPrpPus = true
        } else {
          this.to_pop = !this.to_pop
        }
        return
      } else {
        this.$refs.privacy.showFlag()

      }


    },
    concatShow(index) {
      if (index == 1) {
        this.$router.push({
          path: "/talk",
          query: {
            apply_id: this.user_id,
            type: 1,
            goods_id: this.$route.params.id
          },
        });
      } else {
        this.showPrpPus = false
      }
    },
    concern() {
      if (this.artist_id == this.collector_id || this.artist_id == this.shouchang) {
        return Toast(this.$t('main.cfy'))
      }
      let token = localStorage.getItem('user_token')
      if (token) {
        this.axios.post("/artist/editFollow", { artist_id: this.artist_id }).then((res) => {
          this.follow_status = res.data.data.follow_status
          if (res.data.data.follow_status == 1) {
            Toast.success(this.$t('main.followSuc'), 2000)
          } else {
            Toast(this.$t('main.cancelSuc'))
          }
        })
      } else {
        this.$refs.privacy.showFlag()
      }
    },
    post_price() {
      if (this.shuru) {
        return
      }
      this.shuru = true
      if (!this.to_name) {
        this.msg = this.$t('main.pfiyname')
        this.to_err = true
        this.shuru = false
        return
      } else if (!this.to_phone) {
        this.msg = this.$t('main.peyphone')
        this.to_err = true
        this.shuru = false
        return
      } else if (!this.to_price) {
        this.msg = this.$t('main.peestPrice')
        this.to_err = true
        this.shuru = false
        return
      } else if (!this.to_content) {
        this.msg = this.$t('main.pepurchmess')
        this.to_err = true
        this.shuru = false
        return
      }
      this.axios.post('collection/applyBuy', { name: this.to_name, phone: this.to_phone, price: this.to_price, goods_id: this.$route.params.id, content: this.to_content }).then((res) => {
        this.shuru = false
        if (res.data.code == 0) {
          this.to_name = ''
          this.to_phone = ''
          this.to_price = ''
          this.to_content = ''
          this.to_err = false
          this.wtb()
          this.to_pop = false
          this.pay_status = true

          this.axios
            .get("/goods/info" + "?goods_id=" + this.$route.params.id)
            .then((res) => {
              this.user_id = res.data.data.apply_buy_id;
            })
          weui.alert(this.$t('main.submitsucc'), { buttons: [{ label: this.$t('main.confIrm') }] })
          this.to_pop = false
        } else {
          if (res.data.msg.indexOf('敏感词') != -1) {

            let msg = res.data.msg

            let mgc = msg.split('：')[1]
            weui.alert(this.$t('main.submitmg') + mgc, { buttons: [{ label: this.$t('main.confIrm') }] })
            return
          }
          weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
        }
      })
    },
    guanbiBtn() {
      this.showBtn = true
    },
    change_share_show() {
      this.$refs.share.changeShow()

    },
    changeIndex(index) {
      this.share_act_index = index
    },
    share() {

    },
    async like() {
      if (this.is_like_loading) {
        return
      }
      if (this.is_like) {
        Toast(this.$t('main.isLike'))
        return
      }
      this.is_like_loading = true
      let newdata = ''
      await this.axios.post('common/encrypt',{goods_id: this.$route.params.id, type: 1}).then((res)=>{
        newdata = res.data.data.data_encrypted
      })
      this.axios.post('goods/like', { data:newdata }).then((res) => {
        this.is_like_loading = false
        this.is_like = true
        this.like_num++
        Toast.success(this.$t('main.livesucc'))
      })
    },
    userCollection() {
      let token = localStorage.getItem('user_token')
      if (token) {
        this.axios.post("/goods/editSave", { goods_id: this.$route.params.id }).then((res) => {
          this.save_status = res.data.data.save_status
          if (res.data.data.save_status == 1) {
            Toast.success(this.$t('main.collsucc'), 2000)
            this.total_save_num++
          } else {
            Toast(this.$t('main.cancelSuc'), 2000)
            this.total_save_num--
          }
        })
      } else {
        this.$refs.privacy.showFlag()
      }
    },
    showContent(key) {
      Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm') })
    },
    // 跳转到已上传图片
    toImgUpdate() {
      const id = this.$route.params.id
      this.$router.push(`/adtiViolation/${id}`)
    },
    thousands(num) {
      var splits = [], res = [];
      var splits = num.toString().split(".");
      splits[0].split("").reverse().map(function (item, i) {
        if (i % 3 == 0 && i != 0) { res.push(","); }
        res.push(item);
      });
      return res.reverse().join("") + (splits.length > 1 ? "." + splits[1] : "");
    },
    repairBtn() {
      if (this.showBtn) {
        this.showBtn = false
        return
      }
      
      this.$router.push("/repair")
    },
    swipechange1(e) {
      this.current1 = e;
    },
    swipechange2(e) {
      this.current2 = e;
    },
    go3D() {
      window.location.href = this.dimensional_href
    }
    // throttle(fn, time) {
    // 		let canRun = true;
    // 		return function() {

    // 		if (!canRun)
    // 			return;
    // 		canRun = false;
    // 		setTimeout(() => {
    // 			fn.apply(this);
    // 			canRun = true;
    // 		}, time)
    // 	}
    // },
    // scrollFn(e) {
    // if (this.$refs.out.scrollHeight - this.$refs.out.scrollTop - this.$refs.out.clientHeight <= 20) {

    //     if (this.hasMorePages && !this.loading1) {
    //       this.showLoading = true
    //       const count = 4
    //       this.page++
    //       this.loading1 = true
    //       this.axios
    //       .get("/goods/recommend?artist_id=" + this.artist_id + "&count=" + count  + "&page=" + this.page)
    //       .then((res) => {
    //       this.showLoading = false
    //       this.hasMorePages = res.data.data.hasMorePages
    //       this.loading1 = false
    //         res.data.data.items.forEach((item)=>{{
    //           this.tuijian.push(item)
    //         }})
    //       })

    //   }
    //   }
    // }

  }
}
</script>

<style scoped>
/* div{
  filter:grayscale(100%);
} */
.out {
  box-sizing: border-box;
  background: url(../assets/img/wzw_goods_bg.jpg) no-repeat center;
  background-color: #fafafa;
  background-size: 100% auto;
  background-attachment: fixed;
  filter: none !important;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  position: relative;
}

/deep/ .changelang .c_main {
  justify-content: flex-end;
}

/deep/ .changelang .c_main img {
  margin-right: 0;
}



.flex {
  display: flex;
}

.just-sp {
  justify-content: space-between;
}

.list_return {
  align-items: center;
}

.Title {
  font-size: 0.18rem;
  position: relative;
  text-align: left;
}

.just-cen {
  justify-content: center;
}

.English {
  color: #1b6a21;
  position: relative;
  top: 0.15rem;
  text-align: left;
  left: 0.35rem;
  font-size: 0.15rem;
  opacity: 0.3;
}

.Chinese {
  color: #1b6a21;
  top: -0.2rem;
  left: 0;
  position: relative;
}

.logo {
  height: 0.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mockdiv {
  width: 3.135rem;
}

.logo img {
  display: block;
  width: 0.66rem;
  height: 0.25rem;
  object-fit: contain;
  margin-right: 0.08rem;
}


.van-swipe-item {
  display: inline-block;
}

.logo div {
  color: #9e332e;
  font-size: 0.18rem;
  font-weight: 600;
}

.info_item {
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  color: #767575;
  padding-top: 0.04rem;
  padding-bottom: 0.04rem;
}

.detail_item {
  font-size: 0.15rem;
  color: #767575;
  padding-bottom: 0.04rem;
  padding-top: 0.04rem;
}

.detail_item>div:nth-child(1)>div:nth-child(1) {
  width: 0.15rem;
  padding-left: 0.175rem;
  padding-right: 0.1rem;
}

.detail_item>div:nth-child(1) {
  display: flex;
}

.detail_item>div:nth-child(1)>div:nth-child(1)>img {
  width: 100%;

  object-fit: contain;
}

.detail_item>div:nth-child(1)>div:nth-child(2) {
  line-height: 0.2rem;
}

.detail_item>div:nth-child(1)>div:nth-child(3) {
  width: 2.4rem;
}

.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.25rem;
  right: 0.05rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}

.custom_indicator {
  width: 100%;
  height: 0.235rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b6a21;
}

.custom_indicator1 {
  position: absolute;
  bottom: 0.13rem;
}

.indicator {
  width: 0.07rem;
  height: 0.07rem;
  border-radius: 50%;
  background: #bfbfbf;
  box-sizing: border-box;
}

.indicator_flex {
  display: flex;
}

.indicator_active {
  background: #b25660 !important;
}

.indicator_active1 {
  background: #fff !important;
}

.detail_item>div:nth-child(2) {
  color: #171717;
  margin: 0 0.15rem;
  margin-left: 0.465rem;
}

.detail_item>div:nth-child(3) {
  color: #171717;
  margin: 0 0.15rem;
  margin-left: 0.465rem;
}

.info_item>div:nth-child(1) {
  width: 0.15rem;
  display: flex;
  align-items: center;
  margin-left: 0.175rem;
  margin-right: 0.1rem;
  box-sizing: border-box;
}

.info_item>div:nth-child(1)>img {
  width: 0.15rem;
  object-fit: contain;
}

.info_item>div:nth-child(2) {
  margin-right: 0.05rem;
}

.info_item>div:nth-child(4) {
  flex: 1;
  text-align: left;
  color: #171717;
}

.imgUpdate img {
  width: 100%;
  display: block;
}

.good_name {
  font-size: 0.15rem;
  line-height: 0.2rem;
  width: 2.42rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #202020;
}

.good_big_name {
  font-size: 0.15rem;
}

.share {
  display: flex;
  align-items: center;
  color: #1296db;
  font-weight: 500;
  margin-right: 0.13rem;
}

.share img {
  width: 0.2rem;
  height: 0.2rem;
}

.top_item {
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  height: 0.5rem;
  color: #171717;
  font-size: 0.15rem;
}

.info_img {
  margin-top: 0.07rem;
  margin-bottom: 0.05rem;
  width: 100%;
  height: 3rem;
  overflow: hidden;
}

.info_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info_img_list {
  display: flex;
  align-items: center;
  padding: 0 0.15rem;
  padding-top: 0.02rem;
  padding-bottom: 0.06rem;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.info_img_list img {
  width: 0.54rem;
  height: 0.54rem;
  object-fit: contain;
  margin-right: 0.03rem;
  border-radius: 5px;
  margin: 0 0.02rem;
}

.img_on {
  box-shadow: 0 0 0 2px #2d71d7;
}

.item_top {
  height: max-content;
}

.item_top {
  padding-top: 0.15rem;
  margin-bottom: 0.08rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.user_name {
  color: #202020 !important;
  flex: 3;
}

.price {
  color: #000000 !important;
  font-size: 0.15rem;
  font-weight: bold;
}

.renzhen {
  /* flex: none !important; */
  color: #57bd2a !important;
  /* margin-right: 0.38rem; */
}

.zhuozhe {
  color: #57bd2a !important;
}

.noOne {
  color: #8f8f8f !important;
}

.overHidd {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy {
  border-radius: 0.06rem;
  height: 0.22rem;
  width: 0.45rem;
  margin-right: 0.1rem;
  text-align: center;
  color: #fff;
  font-size: 0.15rem;
  line-height: 0.22rem;
  background: #2d71d7;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

.return {
  width: 0.065rem;
  height: 0.12rem;
  display: block;
}

.item {
  background: rgba(255, 255, 255, 0.5);
  
  margin-bottom: 0.1rem;
}

.item_tg {
  margin-bottom: 0.1rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  background: url(../assets/img/tg_bg.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
}

.success {
  padding-left: 0.85rem;
  height: 0.35rem;
  display: flex;
  align-items: center;
  font-size: 0.13rem;
  color: #fff;
  background: url(../assets/img/shoulu.png) no-repeat;
  background-size: cover;
}

.showMore {
  font-size: 0.12rem;
  color: #626262 !important;
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
}

.master {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 0.15rem;
  color: #767575;
  position: relative;
}

.master_title {
  width: 2rem;
  margin-right: 0.1rem;
  color: #171717;
  font-weight: bold;
  font-size: 0.18rem;
  padding-top: 0.1rem;
  min-height: 0.8rem;
  white-space: pre-wrap;
}

.master_text {
  display: flex;
  align-items: center;
  margin-bottom: 0.15rem;
}

.master_text img {
  width: 0.15rem;
  object-fit: contain;
  margin-right: 0.1rem;
}

.master_text>.test-title {
  margin-right: 0.05rem;
  font-size: 0.15rem;
}

.master_text>.test-mc {
  margin-right: 0.05rem;
  font-size: 0.18rem;
  color: #9a2a25;
  width: 1.1rem;
}

.master_text .text-created {
  color: black;
  font-size: 0.14rem;
}

.scjg_text{
  display: block;
  margin-top: 0.1rem;
}
.scjg_text img {
  display:inline;
}
.scjg_text>.test-title{
  display:inline;
}
.scjg_text>.test-mc{
  display:block;
  width:max-content;
  width: 2.05rem;
}
.master>div:nth-child(2) {
  margin-right: 0.3rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 1.2rem;
  height: 1.53rem;
}

.master>div:nth-child(2)>svg {
  display: block;
  width: 100%;
  height: 100%;
}

.master>div:nth-child(2)>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.bigText {
  width: 100%;
  box-sizing: border-box;
  padding-right: 0.15rem;
  padding-left: 0.15rem;
  font-size: 0.15rem;
  padding-bottom: 0.1rem;
  text-align: left;
}

.bigText img {
  display: block;
  width: 0.15rem;
  object-fit: contain;
  padding-right: 0.1rem;
}

.bigText>div:nth-child(1) {
  display: flex;
  font-size: 0.15rem;
  color: #767575;
}

.bigText>div:nth-child(2) {
  margin-top: 0.15rem;
  color: #171717;
}

.bigText>div:nth-child(3) {
  margin-top: 0.15rem;
  color: #171717;
}

.noMore {
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 5;

  -webkit-box-flex: 5;
}
.noMore2 {
  overflow: hidden;

  text-overflow: ellipsis;

  -webkit-box-orient: vertical;

  display: -webkit-box;

  -webkit-line-clamp: 2;

  -webkit-box-flex: 2;
}
.swipe_img {
  width: 100%;
  height: 2.65rem;
  object-fit: contain;
  display: block;
  /* background: #fff; */
  /* padding: 0.1rem 0; */

  /* user-drag:none; */
}

@keyframes rotateyy {
  0%{
    transform: rotateY(0deg);
  }
  100%{
    transform: rotateY(360deg);
  }
}

@keyframes tranY {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(-0.14rem);
  }
  100%{
    transform: translateY(0);
  }
}
.d_enter {
  position: absolute;
  left: 0.12rem;
  top: 2.55rem;
  /* animation: rotateyy 4s infinite ease-in-out ; */
  animation-delay: 2s;
}

.d_enter>img:nth-child(1) {
  width: 0.7rem;
  height: 0.45rem;
  object-fit: contain;
  position: absolute;
  z-index: 1;
  animation: tranY 4s infinite ease-in-out ; 
  /* filter: drop-shadow(4px 8px 3px #aaa); */
}
.d_enter>img:nth-child(2){
  width: 0.8rem;
  height: 0.38rem;
  object-fit: contain;
  position: absolute;
  left: -0.05rem;
  bottom: -0.8rem;
  z-index: 0;
}

.d_enter .english {
  width: 0.68rem;
  height: 112rem;
  object-fit: contain;
}


.good {
  width: 1.6rem;
  margin-top: 0.2rem;
}

.good_text {
  font-size: 0.17rem;
  color: #9a2a25;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  width: 1.66rem;
  height: 0.48rem;
  padding: 0 0.1rem;
  line-height: 0.48rem;
  text-overflow: ellipsis;
  text-align: left;
  background: #fff;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-flex: 1;
}

.good .good-image {
  width: 100%;
  height: 2.4rem;
  object-fit: cover;
}

.good-image-left {
  position: relative;
}

.good-image-left .icon-min {
  width: 0.2rem;
  position: absolute;
  bottom: 0.02rem;
  right: 0.04rem;
}

.good-image-left .icon-min img {
  width: 100%;
  height: 100%;
}

.good .good-title {
  width: 100%;
  height: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.good .good-title .good_name {
  
  font-size: 0.15rem;
  line-height: 0.17rem;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #000000;
  height: 0.4rem;
}

.good .good-title .good_price {
  font-size: 0.12rem;
  color: #000000;
  margin: 0.02rem 0 0.05rem;
}

.good .good-title .good_description {
  font-size: 0.14rem;
  line-height: 0.15rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #333333;
  height: 0.3rem;
}

.good_list {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  padding-bottom: 0.15rem;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.new_item {
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.15rem;
  padding-right: 0.19rem;
  padding-bottom: 0.11rem;
  font-size: 0.15rem;
  color: #171717;
  display: flex;
}

.new_item::before {
  content: "";
  width: 0.11rem;
  height: 0.11rem;
  box-shadow: inset 0 0 0 1px #472400 , inset 0 0 0 0.03rem #fff;
  background-color: #472400;
  border-radius: 50%;
  margin: auto 0;
  margin-right: 0.085rem;
}

.new_item>div:nth-child(1) {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new_item>div:nth-child(2) {
  margin-left: 0.19rem;
}

.icon {
  position: absolute;
  top: 0.06rem;
  left: 0.09rem;
  width: 0.2rem;
  height: 0.2rem;
}

.icon img {
  width: 100%;
  height: 100%;
}

.collector {
  display: flex;
  align-items: center;
}

.collector img {
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 0.06rem;
  display: block;
  margin-right: 0.08rem;
}

.collector div {
  width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #b38924;
}

.moreText {
  font-size: 0.15rem;
}

.cw_title-wrap {
  display: flex;
  justify-content: space-between;
}

.cw_title  {
  position: relative;
  font-size: 0.15rem;
  color: #472400;
  width: 1.32rem;
  height: 0.285rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/img/wzw_title.png) no-repeat;
  background-size: 100% 100%;
  font-weight: bold;
  box-sizing: border-box;
  padding-top: 0.03rem;
}




.scroll-loding {
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.01rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.05rem;
}

.text_bottom {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.15rem;
  color: #929292;
  font-size: 0.11rem;
  text-align: center;
  padding-bottom: 0.6rem;
  padding-top: 0.1rem;
  background: #f0f0f0;
}

.hisss {
  position: relative;
  margin-right: 1px;
  background: url(../assets/img/hisss.png) no-repeat;
  width: 0.44rem;
  height: 0.2rem;
  margin-right: 0.1rem;
  background-size: 100% 100%;
  font-size: 0.15rem;
  color: #9A2A25;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hisNum {
  width: 0.16rem;
  height: 0.16rem;
  font-size: 0.11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fd1c1b;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  right: -0.08rem;
  top: -0.08rem;
}

.quxianx {
  margin-right: 0.1rem;
  background: url(../assets/img/quxianx.png) no-repeat;
  width: 0.44rem;
  height: 0.2rem;
  background-size: 100% 100%;
  font-size: 0.15rem;
  color: #223333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed_buttom .speak_fixed {
  position: fixed;
  bottom: 0.65rem;
  right: 0.08rem;
}

.AR-Read-top {
  position: fixed;
  top: 3.05rem;
  right: 0.1rem;
}

.AR-Read {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AR-Read .zanting {
  height: 0.35rem;
  width: 0.45rem;
  margin-top: 0.05rem;
}

.AR-Read .AR-bofang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
}

.AR-Read .AR-bofang .AR-box>img {
  height: 0.35rem;
  width: 0.45rem;
  /* position: absolute; */
  /* left:-0.12rem; */
  margin-left: -0.3rem;
}

.AR-Read .AR-bofang .AR-box {
  background: #E9EFFF;
  border-radius: 0.05rem;
  width: 2rem;
  height: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.1rem;
}

.AR-Read .AR-bofang .AR-box .tiao-wrap {
  position: relative;
  display: flex;
  width: 1.1rem;
  height: 0.2rem;
  align-items: center;
  margin-right: 0.05rem;
}

.AR-Read .AR-bofang .AR-box .time {
  width: 0.5rem;
  text-align: center;
  font-size: 0.12rem;
}

.AR-Read .AR-bofang .AR-box .guanbi {
  padding-right: 0.05rem;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 0.03rem;
}

.AR-Read .AR-bofang .AR-box .guanbi img {
  width: 0.1rem;
  height: 0.1rem;
}

.aud {
  display: none;
}

.fixed_buttom .wtb_fixed {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -1px 6px 0 #626262;
  height: 0.5rem;
  background-color: #edeae4;
}

.wtb {
  margin: 0.07rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  background: url(../assets/img/view-more.png) no-repeat;
  width: 2.21rem;
  height: 0.36rem;
  background-size: 100% 100%;
  color: #333;

}

.copyy {
  margin-right: 0.1rem;
  background: url(../assets/img/wzw_coppy.png) no-repeat;
  width: 0.44rem;
  height: 0.2rem;
  
  background-size: 100% 100%;
  font-size: 0.12rem;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en-css .quxianx,
.en-css .hisss,
.en-css .copyy {
  font-size: 0.13rem;
}

.pop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
  /* display: none; */
}

.pop-in1 .title {
  font-size: 0.18rem;
  display: flex;
  font-weight: bold;
  align-items: center;
}

.pop-in1 .title span {
  margin: 0 0.1rem;
}

.pop-in1 .title img {
  width: 0.18rem;
}

.pop-in1 .con .con-tips {
  margin: 0.2rem 0;
}

.pop-in1 .con .pass-input>div {
  display: flex;
  align-items: center;
  margin-bottom: 0.075rem;
}

.pop-in1 .con .pass-input>div label {
  min-width: 20%;
  text-align: right;
}

.en-css .pop-in1 .con .pass-input>div label {
  max-width: 20%;
  text-align: left;
}

.pop-in1 .con .pass-input>div input {
  height: 0.33rem;
  line-height: 0.33rem;
  font-size: 0.14rem;
  border-radius: 0.05rem;
  border: none;
  outline: none;
  padding-left: 0.1rem;
  width: 79%;
  box-sizing: border-box;
}

.pop-in1 .con .pass-input>div textarea {
  height: 0.66rem;
  line-height: 0.33rem;
  font-size: 0.14rem;
  border-radius: 0.05rem;
  border: none;
  outline: none;
  padding-left: 0.1rem;
  width: 79%;
  box-sizing: border-box;
}

.pop-in1 .con .err-tips {
  padding-left: 20%;
  display: flex;
  align-items: center;
  color: #ff180e;
}

.pop-in1 .con .err-tips img {
  width: 0.13rem;
  margin-right: 0.1rem;
}

.pop-in1 .confirm-btn {
  font-size: 0.14rem;
  height: 0.33rem;
  border-radius: 0.05rem;
  width: 100%;
  margin: 0 auto;
  width: 70%;
  text-align: center;
  color: #ffffff;
  background: #9a2a25;
  line-height: 0.33rem;
  margin-top: 0.22rem;
}

.con-title {
  display: flex;
  justify-content: center;
  margin-top: 0.12rem;
}

.pop-in1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #edeae4;
  padding: 0.15rem 0.25rem;
  color: #171717;
  font-size: 0.12rem;
  width: 93%;
  box-sizing: border-box;
}

.con-title {
  display: flex;
  justify-content: center;
  margin-top: 0.12rem;
  color: "#888888";
}

.detail_right {
  position: fixed;
  width: 0.365rem;
  height: 0.37rem;
  background: url(../assets/img/detail_right.png) no-repeat;
  background-size: 100%;
  z-index: 2;
  right: 0.175rem;
  bottom: 0.08rem;
}

.pt_text {
  font-size: 0.12rem;
  padding: 0 0.2rem;
}

.pt_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0.05rem 0.325rem;
  font-size: 0.15rem;
  color: #262626;
}

.pt_logo_list {
  margin-top: 0.1rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.2rem;
}

.pt_logo img {
  width: 0.35rem;
  height: 0.35rem;
}

.pt_logo {
  width: 0.48rem;
  height: 0.55rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.05rem 0.05rem 0 0;
}

.pt_flex {
  display: flex;
  align-items: center;
}

.pt_item img {
  width: 0.22rem;
  height: 0.22rem;
  margin-right: 0.1rem;
}

.act_logo {
  background: rgb(254, 229, 227, 0.8);
}

.pt_sm {
  background: rgb(254, 229, 227, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 3.35rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0.15rem 0.06rem;
  border-radius: 0.05rem;
  margin-bottom: 0.1rem;
}

.logo_tip {
  margin-top: 0.05rem;
  width: 0.175rem;
  height: 0.02rem;
  border-radius: 0.01rem;
}

.act_logo .logo_tip {
  background-color: #ac5854;
}

.pt_sm_0 {
  border-radius: 0 0.05rem 0.05rem 0.05rem;
}

.jietu {
  background: url(../assets/img/blue_border.png) no-repeat;
  background-size: 100%;
  width: 0.62rem;
  height: 0.165rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  color: #082958;
  line-height: 0.12rem;
}

.seeMore {
  width: 0.65rem;
  height: 0.26rem;
  background: url(../assets/img/my-zb-btn.png) no-repeat;
  background-size: 0.6rem 0.2rem;
  background-position: center center;
  background-color: #9a2a25;
  font-size: 0.12rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.05rem;
}

.pt_sm_text {
  width: 2.4rem;
  font-size: 0.12rem;
  color: #171717;
  line-height: 0.16rem;
}

.pt_sm_title {
  font-size: 0.14rem;
  line-height: 0.16rem;
  color: #171717;
  font-weight: bold;
  margin-right: 0.05rem;
}

/* .button_icon{
  font-size: 0.1rem;
  line-height: 0.12rem;
  text-align: center;
}
.button_icon img{
  height: 0.16rem;
  object-fit: contain;
} */
.share_pop {
  background: rgba(0, 0, 0, 0.7);
}

.share_top {
  position: absolute;
  left: 1rem;
  top: 0.2rem
}

.share_top img {
  width: 2.48rem;
  object-fit: contain;
}

.share_bottom {
  position: absolute;
  bottom: 0.6rem;
  left: 50%;
  transform: translateX(-50%);
}

.share_bottom img {
  width: 1.825rem;
  object-fit: contain;
}

.question {
  width: 0.15rem;
  height: 0.15rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  margin-left: -0.05rem;
}

.question1 {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.05rem;
  margin-top: 0.05rem;
  margin-bottom: 0.05rem;
  position: absolute;
  top: calc(50% - 0.2rem);
  left: 0.15rem;
}

.pop-qrcode {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../assets/img/longtap2.png) no-repeat;
  background-size: 100% 100%;
  width: 2.56rem;
  height: 2.825rem;
  padding-top: 0.15rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.pop-qrcode img {
  width: 2.27rem;
  height: 2.27rem;

  box-sizing: border-box;

  object-fit: contain;
}

.my-swipe {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.swipe-zhengshu {
  width: 92%;
  margin: 0 auto;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  margin: 0.05rem auto;
}

.creti-item {
  width: 100%;
  height: 2rem;
  overflow: hidden;
}

.creti-item .creti-image {
  max-width: 98%;
  height: 2rem;
}

.custom-indicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.1rem;
  right: 0.1rem;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.1rem;
  padding: 0.02rem 0.08rem;
  border-radius: 0.1rem;
  color: #fff;
}

.dr-title {
  text-align: center;
  color: #888;
  padding-bottom: 0.08rem;
}

.cw-right {
  display: flex;
  justify-content: space-between;
  
}

.cw-right .item-logo {
  width: 33.3%;
  display: flex;
  justify-content: center;
  margin: 0.1rem 0 0.08rem;
}

.cw-right .item-logo:nth-of-type(3) {
  border-right: none;
}

.cw-right .item-logo .icon-logo{
  display: flex;
  align-items: center;
}
.cw-right .item-logo .icon-logo div {
  font-size: 0.12rem;
  display: flex;
  justify-content: center;
  color: #535353;
}

.cw-right .item-logo .icon-logo .title-color {
  color: #952012;
}

.cw-right .item-logo .icon-logo img {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.05rem;
}

.pop-pus {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pop-pus .bg-img {
  width: 85%;
  height: 1.5rem;
  background: url(../assets/img/no-data-prompt.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
}

.pop-pus .title-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0.15rem;

}

.pop-pus .title-prompt {
  color: white;
  font-size: 0.15rem;
  width: 2.4rem;
  text-align: center;
}

.pop-pus .sure-logo {
  position: absolute;
  bottom: 0;
  margin-bottom: 0.25rem;
  width: 100%;
  padding: 0 0.4rem;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.sure-logo .button {
  width: 1.1rem;
  height: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  border-radius: 0.03rem;
}

.sure-logo .button:nth-child(1) {
  color: #f8d9ae;
  background: #266477;
  box-shadow: inset 0 0 0 1px #f8d9ae;
}

.sure-logo .button:nth-child(2) {
  color: #266477;
  background: #f8d9ae;
}

.pop-pus .sure-img {
  width: 45%;
}

.guanzhu-logo {
  display: flex;
  justify-content: center;
  border: 0.01rem solid #ccc;
}

.guanzhu-logo .guanzhu-item {
  width: 33.3%;
  display: flex;
  justify-content: center;
  margin: 0.1rem 0 0.08rem;
}

.guanzhu-logo .guanzhu-item div {
  font-size: 0.14rem;
  display: flex;
  justify-content: center;
  color: #535353;
}

.guanzhu-logo .guanzhu-item .title-color {
  color: #952012;
}

.guanzhu-logo .guanzhu-item img {
  width: 0.20rem;
  height: 0.20rem;
}

.report {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3rem;
  color: #9A2A25;
  font-size: 0.14rem;
  filter: brightness(0.1);
}

.report img {
  width: 0.14rem;
  height: 0.14rem;
  margin-right: 0.1rem;
  vertical-align: top;
  font-size: 0;
}

/deep/.van-slider__button {
  background-color: #350A80;
}

.repair {
  display: flex;
  justify-content: flex-end;
}

.repair .repair-rukou {
  width: 0.6rem;
  height: 0.7rem;
  position: relative;
}

.repair .repair-rukou.active {
  right: -0.4rem;
}

.repair div {
  position: relative;
}

.repair div .guanbi {
  width: 0.15rem;
  position: absolute;
  left: -0.06rem;
  top: -0.14rem;
  z-index: 1;
}
.scjg_img{
  display: inline-block;
   
    width: 0.24rem;
    height: 0.24rem;
    vertical-align: middle;
    position: relative;
   
    bottom: 0.02rem;
}
.color17{
  color: #171717;
}
.last-item{
  margin-bottom: 0!important;
}
.privacy{
  width: 100%;
  height: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.11rem;
  box-sizing: border-box;
  font-size: 0.12rem;
  line-height: 0.15rem;
  display: none;
}
.privacy img{
  width: 0.14rem;
  height: 0.15rem;
  margin-right: 0.07rem;
  object-fit: contain;
  display: block;
}
.empty-out{
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
}
.empty-content{
  flex: 1;
  color: #9a2a25;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.22rem;
  box-sizing: border-box;
  padding-bottom: 1.4rem;
}
.empty-content img{
  width: 2.15rem;
  height: 1.35rem;
  margin-bottom: 0.2rem;
  filter: brightness(0.8);
}
.wzw_rz{
    width: 100%;
    height: 0.325rem;
}
.wzw_rz img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.pr_t_honor{
    width: 100%;
    min-height: 1.13rem;
    background: url(../assets/img/wzw_honor_bg-min.jpg) no-repeat;
    background-size: 100% auto;
    box-sizing: border-box;
    border-radius: 0.03rem;
    overflow-y: auto;
    padding: 0.15rem;
    max-height: 3.83rem;

}
.pr_t_h_top{
    display: flex;
    align-items: center;
    font-size: 0.15rem;
    color: #472400;
    font-weight: bold;
}
.pr_t_h_top img{
    width: 0.125rem;
    height: 0.14rem;
    margin-right: 0.07rem;
}
.pr_tt_info{
}
.pr_tt_info > div:nth-child(1){
    font-size: 0.18rem;
    color: #000000;
}
.pr_tt_info > div:nth-child(2){
    font-size: 0.14rem;
    color: #000000;
}
.pr_tt_follow{
    width: 0.52rem;
    height: 0.25rem;
    margin-left: auto;
    background: url(../assets/img/wzw_follow.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.12rem;
}
.pr_show_more{
    margin-top: 0.1rem;
    font-size: 0.12rem;
    color: #0075f4;
    margin-bottom: 0.3rem;
}
.wzw_tr{
  box-sizing: border-box;
  padding: 0 0.15rem;
  font-size: 0.18rem;
  color: #000000;
  max-height: 0.50rem;
  line-height: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0.1rem;
  font-weight: bold;
  margin-bottom: 0.05rem;
}
.pr_top{
  margin-top: 0.1rem;
  font-size: 0.14rem;
  color: #333333;
  margin-bottom: 0.1rem;
}
.pr_master{
  display: flex;
  align-items: center;
  margin-bottom: 0.1rem;
}
.pr_master .pr_master_avatar{
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.1rem;
  border-radius: 50%;
  overflow: hidden;
}
.pr_master_avatar img{
  width: 100%;
  object-fit: cover;
}
.pr_t_h_h{
  max-height: 2.9rem;
  overflow-y: auto;
}
</style>